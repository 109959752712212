import { SVGProps } from 'react'

export const AsteriskIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_430_31705"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_430_31705)">
      <path
        d="M11.6501 20.35C11.4334 20.35 11.2542 20.2792 11.1126 20.1375C10.9709 19.9959 10.9001 19.8167 10.9001 19.6V13.925L5.97507 16.775C5.79173 16.875 5.60007 16.9042 5.40007 16.8625C5.20007 16.8209 5.05007 16.7084 4.95007 16.525L4.60007 15.925C4.4834 15.7417 4.45423 15.5417 4.51257 15.325C4.5709 15.1084 4.69173 14.95 4.87507 14.85L9.80007 12L4.87507 9.17502C4.69173 9.07502 4.57507 8.92502 4.52507 8.72502C4.47507 8.52502 4.50007 8.33336 4.60007 8.15002L4.97507 7.50002C5.07507 7.31669 5.22507 7.20419 5.42507 7.16252C5.62507 7.12086 5.81673 7.15002 6.00007 7.25002L10.9001 10.1V4.40002C10.9001 4.18336 10.9709 4.00419 11.1126 3.86252C11.2542 3.72086 11.4334 3.65002 11.6501 3.65002H12.3501C12.5667 3.65002 12.7459 3.72086 12.8876 3.86252C13.0292 4.00419 13.1001 4.18336 13.1001 4.40002V10.1L18.0001 7.25002C18.1834 7.15002 18.3751 7.12086 18.5751 7.16252C18.7751 7.20419 18.9251 7.31669 19.0251 7.50002L19.4001 8.15002C19.5001 8.33336 19.5251 8.52502 19.4751 8.72502C19.4251 8.92502 19.3084 9.07502 19.1251 9.17502L14.2001 12L19.1251 14.85C19.3084 14.95 19.4334 15.1 19.5001 15.3C19.5667 15.5 19.5417 15.6917 19.4251 15.875L19.0251 16.525C18.9251 16.7084 18.7751 16.8209 18.5751 16.8625C18.3751 16.9042 18.1834 16.875 18.0001 16.775L13.1001 13.925V19.6C13.1001 19.8167 13.0292 19.9959 12.8876 20.1375C12.7459 20.2792 12.5667 20.35 12.3501 20.35H11.6501Z"
        fill="currentColor"
      />
    </g>
  </svg>
)
