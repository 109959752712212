import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import { AuthProvider } from 'constants/common/auth'
import { Route } from 'constants/common/routes'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { SignInValidationSchema, SignInValidationSchemaType } from './SignInValidationSchema'

export const SignInForm = () => {
  const formMethods = useForm<SignInValidationSchemaType>({
    resolver: zodResolver(SignInValidationSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  })

  const { push, query } = useRouter()

  const { callbackUrl } = query

  return (
    <Form {...formMethods}>
      <form
        className="w-full flex flex-col gap-4"
        onSubmit={(e) => {
          e.preventDefault()
          void formMethods.handleSubmit(async ({ email, password }) => {
            const signInRes = await signIn(AuthProvider.Cognito, {
              email,
              password,
              callbackUrl: callbackUrl ? String(callbackUrl) : Route.Dashboard,
              redirect: false,
            })
            if (signInRes?.error) {
              formMethods.setError('root', {
                type: 'manual',
                message: 'Incorrect email address or password.',
              })
            } else {
              void push(callbackUrl ? String(callbackUrl) : Route.Dashboard)
            }
          })()
        }}
      >
        <FormField
          required
          name="email"
          control={formMethods.control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} size="lg" type="email" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          required
          name="password"
          control={formMethods.control}
          render={({ field }) => (
            <FormItem>
              <div className="flex justify-between">
                <FormLabel>Password</FormLabel>
                <Link
                  href={Route.ForgotPassword}
                  className="text-xs text-slate-500 hover:underline"
                >
                  Forgot password?
                </Link>
              </div>
              <FormControl>
                <Input {...field} size="lg" type="password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {formMethods.formState.errors.root && (
          <FormMessage>Incorrect email address or password</FormMessage>
        )}
        <Button type="submit" className="uppercase h-[52px]" colorType="mint">
          Sign in
        </Button>
      </form>
    </Form>
  )
}
