import { z } from 'zod'

export const SignInValidationSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Please enter your email address')
    .email({ message: 'Enter a valid email address.' }),
  password: z.string().min(1, 'Please enter your password'),
})

export type SignInValidationSchemaType = z.infer<typeof SignInValidationSchema>
