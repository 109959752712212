import React from 'react'
import { getNotificationBar } from 'api/integrations/storyblok/datasources/getNotificationBar'
import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { SignInForm } from 'components/auth/SignInForm'
import Footer from 'components/landing-page/Footer'
import NavbarLayout from 'components/navbar/NavbarLayout'
import { serverSideTranslations } from 'utils/i18n'

const SignIn = ({ notificationBar }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { t } = useTranslation('common')

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    <NavbarLayout notificationBar={notificationBar.notificationBar?.items}>
      <div className="w-full flex justify-center">
        <div className="flex w-full flex-col lg:flex-row max-w-[1436px] gap-20 lg:gap-16 xl:gap-32 2xl:gap-56 justify-center items-center lg:items-start py-20 lg:py-28 px-8 sm:px-12 md:px-16">
          <div className="max-w-[450px] w-full hidden lg:block">
            <Image
              src="/images/welcome-back.png"
              alt="Become a coach poster"
              width={450}
              height={450}
              className="rounded-3xl"
              priority
            />
          </div>
          <div className="w-full md:max-w-[500px]">
            <h2 className="text-4xl font-medium mb-11 self-start">{t('auth.signIn.signIn')}</h2>
            <SignInForm />
          </div>
        </div>
      </div>
      <Footer />
    </NavbarLayout>
  )
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const notificationBar = await getNotificationBar()

  return {
    props: { ...(await serverSideTranslations(context, ['common'])), notificationBar },
  }
}

export default SignIn
